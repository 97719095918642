import request from '@/utils/request'
import memberRequest from '@/utils/memberRequest'
import Qs from 'qs'

//获取用户信息
export function getInfo() {
  return memberRequest({
    url: '/members/info',
    method: 'post',
    params: { 'guard_name':'website-pc-member'}
  })

  // return new Promise((resolve, reject) => {

  //   const res = {
  //     data: {
  //       truename: '司志强',
  //       account: 'strong',
  //       menu: [{
  //           description: '简介新增',
  //           name: 'intro'
  //         },
  //         {
  //           description: '名片管理',
  //           name: 'card'
  //         },
  //         {
  //           description: '城市管理',
  //           name: 'city'
  //         },
  //         {
  //           description: '拟上市管理',
  //           name: 'prepare'
  //         },
  //         {
  //           description: '正在公告',
  //           name: 'notice'
  //         }

  //       ],
  //       button: [{
  //           description: '简介新增',
  //           name: 'introadd'
  //         },
  //         {
  //           description: '简介删除',
  //           name: 'introdelete'
  //         },
  //         {
  //           description: '简介编辑',
  //           name: 'introedit'
  //         },
  //         {
  //           description: '名片新增',
  //           name: 'cardadd'
  //         },
  //         {
  //           description: '名片删除',
  //           name: 'carddelete'
  //         },
  //         {
  //           description: '名片编辑',
  //           name: 'cardedit'
  //         }

  //       ]
  //     }
  //   }
  //   return resolve(res)

  // })

}



// 给用户分配角色
export function RoleListByUid(data) {
  return request({
    url: '/backend/auth/role/assign-list',
    method: 'post',
    params: data
  })
}

// 给用户分配角色
export function userSetRole(data) {
  return request({
    url: '/backend/auth/role/to-user',
    method: 'post',
    params: data
  })
}

export function login(data) {
  return requestUcenter({
    url: '/admin/login',
    method: 'post',
    params: data
  })
}



export function logout() {
  return requestUcenter({
    url: '/admin/logout',
    method: 'post'
  })
}



export function detail(params) {
  return request({
    url: '/auth/detail',
    method: 'post',
    params
  })
}

export function del(param) {
  return request({
    url: '/auth/delete',
    method: 'post',
    params: {
      id: param.id
    }
  })
}

export function edit(param) {
  const n = {}
  n.id = param.id
  n.name = param.name
  n.true_name = param.true_name
  n.password = param.password
  n.phone = param.phone
  n.city_plate_id = param.city_plate_id.toString()
  n.role = param.role.toString()

  return requestUcenter({
    url: '/auth/edit',
    method: 'post',
    params: n
  })
}

// /===========================以下是自定义的
export function register(params) {


  return requestUcenter({
    url: '/admin/register',
    method: 'post',
    data:Qs.toString(params)
  })
}


export function getUserList(params) {
  return requestUcenter({
    url: '/admin/list',
    method: 'post',
    params
  })
}

//管理禁用
export function disable(params) {
  return requestUcenter({
    url: '/admin/disable',
    method: 'post',
    params
  })
}

//管理启用
export function able(params) {
  return requestUcenter({
    url: '/admin/enable',
    method: 'post',
    params
  })
}
