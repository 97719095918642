
/**
 * 这个方法可以将所有的嵌套对象和拷贝值缓存起来。
 * 如果检测到循环结构就将其拷贝值赋给相应的key以避免无限循环
 *
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */
export function deepCopy (obj, cache = []) {
  // 首先判断对象是不是一个object类型，包括对象和数组，需要排除null。如果都不符合说明是一个基本类型值， 可以直接赋值
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // 这里我们检查缓存中保存的所有对象有没有就是当前我们需要进行深拷贝的对象，如果有说明是一个循环结构，直接将它的拷贝值返回即可
  const hit = find(cache, c => c.original === obj)
  if (hit) {
    return hit.copy
  }

  // 使用一个全新的对象进行接收拷贝
  const copy = Array.isArray(obj) ? [] : {}
  // 把当前值作为原值和拷贝后的值塞入缓存队列，在后续的递归中，cache保存着整个过程的状态
  cache.push({
    original: obj,
    copy
  })

  // 按key遍历，并递归这个过程
  Object.keys(obj).forEach(key => {
    copy[key] = deepCopy(obj[key], cache)
  })

  return copy
}

