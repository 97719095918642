import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'lib-flexible' //rem转化器

// import BaiduMap  from 'vue-baidu-map'
// import VueBMap from 'vue-bmap-gl';
// import 'vue-bmap-gl/dist/style.css'
// import VueMapvgl from 'vue-mapvgl';
// VueBMap.initBMapApiLoader({
//   ak: 'oe2YIR6kRoAbqlsbMA1NTFzUttRNQLLL',
//   v: '1.0',
//   plugins:'DistanceTool'
// });



// import BaiduMap from 'vue-baidu-map'

// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: 'oe2YIR6kRoAbqlsbMA1NTFzUttRNQLLL'
// })


// Vue.use(VueBMap);
// Vue.use(VueMapvgl);
Vue.prototype.$axios = axios

// console.log(VueBMap)
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '/public/css/main.css'

import './styles/element-variables.scss'
import MoQuarterPicker from "mo-quarter-picker";
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(MoQuarterPicker);

//引入视频播放器
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

//引入
import '@/permission' // permission control

import '@/directive/vdown'


//引入自定义按钮权限指令类

import directivePermission from '@/utils/directivePermission.js'
//全局注册按钮权限指令
Vue.directive('permission', directivePermission)




import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'


import uweb from 'vue-uweb'
Vue.use(uweb, 2290980)

Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
})



// 跳转后返回顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
// alert(1)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// alert(2)