import axios from 'axios'
import Cookies from 'js-cookie'

import store from '@/store'

import {
  MessageBox,
  Message
} from 'element-ui'
import router from '@/router'

import {
  showFullScreenLoading,
  endLoading,
  tryHideFullScreenLoading
} from './loading'




// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 5000 // request timeout
})

var that = this
// request interceptor
service.interceptors.request.use(
  config => {
    console.log('config', config)
    const setLoding = config.loading != null ? config.loading : false
    if (setLoding) {
      showFullScreenLoading()
    }



    if (store.state.user.token) {
      //设置header头
      config.headers['Authorization'] = 'Bearer ' + store.state.user.token
    } else {
      // alert('interceptors')


      window.location.href = process.env.VUE_APP_LOGIN_URL + '?url=' + window.btoa(unescape(encodeURIComponent(window.location)))
    }
    return config
  },
  error => {

    endLoading()
    return Promise.reject(error)
  }

)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {

    tryHideFullScreenLoading()

    const res = response.data


    if (res.code === 401) {
      store.commit('setToken', '')
      localStorage.setItem('token', '')
      Cookies.set('token', '', {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN
      })
      Cookies.remove('token')
      store.commit('userinfo', '')
      Cookies.set('userinfo', '', {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN
      })
      Cookies.remove('userinfo')

      sessionStorage.clear();
      Cookies.set()
      window.location.href = process.env.VUE_APP_LOGIN_URL
      return
    }



    if (res.code !== 1 && res.code !== 1001) {

      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
    return res
  },
  error => {

    endLoading()
    // console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service