import { Loading } from 'element-ui'
let loading = null // 定义loading变量
// 开始 加载loading
export const startLoading = () => {
    loading = Loading.service({
        fullscreen: true, // 是否全屏
        lock: true, // 是否锁定全屏滚动
        text: '文件生成中...',
        spinner: 'el-icon-loading', // 自定义图标，不填为默认的，填入不存在的找不到图标就不显示
        // background: 'rgba(0, 0, 0, 0.3)', // 自定义背景色
        customClass: 'osloading' // 自定义类名
    })
}
// 结束 取消loading加载
export const endLoading = () => {
    loading.close()
}
// showFullScreenLoading() 与 tryHideFullScreenLoading() 目的是合并同一页面多个请求触发loading
let needLoadingRequestCount = 0 // 声明一个变量
export const showFullScreenLoading = () => {
    if (needLoadingRequestCount === 0) {
        // 当等于0时证明第一次请求 这时开启loading
        startLoading()
    }
    needLoadingRequestCount++ // 全局变量值++
}
 
export const tryHideFullScreenLoading = () => {
    if (needLoadingRequestCount <= 0) {
        return
    } // 小于等于0 证明没有开启loading 此时return
    needLoadingRequestCount-- // 正常响应后 全局变量 --
    if (needLoadingRequestCount === 0) {
        // 等于0 时证明全部加载完毕 此时结束loading 加载
        endLoading()
    }
}