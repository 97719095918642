module.exports = {

  title: '土拍网-土地投资门户',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,

  //上线域名与站点对应配置

  // siteMapDomain: [{
  //     sitePath: '/wuxi/index',
  //     domain: 'xi.chinatupai.com'
  //   },
  //   {
  //     sitePath: '/suzhou/index',
  //     domain: 'su.chinatupai.com'
  //   },
  //   {
  //     sitePath: '/changzhou/index',
  //     domain: 'chang.chinatupai.com'
  //   },
  //   {
  //     sitePath: '/nanjing/index',
  //     domain: 'jing.chinatupai.com'
  //   },
  //   {
  //     sitePath: '/nantong/index',
  //     domain: 'tong.chinatupai.com'
  //   },
  //   {
  //     sitePath: '/nanchang/index',
  //     domain: 'gan.chinatupai.com'
  //   },
  //   {
  //     sitePath: '/hefei/index',
  //     domain: 'fei.chinatupai.com'
  //   },
  //   {
  //     sitePath: '/hangzhou/index',
  //     domain: 'hang.chinatupai.com'
  //   }
  // ]

  siteMapDomain: [{
    sitePath: '/wuxi/index',
    domain: 'wuxi.chinatupai.com'
  },
  {
    sitePath: '/suzhou/index',
    domain: 'suzhou.chinatupai.com'
  },
  {
    sitePath: '/changzhou/index',
    domain: 'changzhou.chinatupai.com'
  },
  {
    sitePath: '/nanjing/index',
    domain: 'nanjing.chinatupai.com'
  },
  {
    sitePath: '/nantong/index',
    domain: 'nantong.chinatupai.com'
  },
  {
    sitePath: '/nanchang/index',
    domain: 'nanchang.chinatupai.com'
  },
  {
    sitePath: '/hefei/index',
    domain: 'hefei.chinatupai.com'
  },
  {
    sitePath: '/hangzhou/index',
    domain: 'hangzhou.chinatupai.com'
  }
]

}