import {
  login,
  logout,
  getInfo
} from '@/api/user'


import Cookies from 'js-cookie'

import {
  getToken,
  setToken,
  removeToken,
  setSuper,
  getSuper
} from '@/utils/auth'
import router, {
  resetRouter
} from '@/router'

const state = {
  token: getToken(),
  userinfo: Cookies.get('userinfo') === undefined ? '' : Cookies.get('userinfo'),
  name: '',
  menu: sessionStorage.getItem('menu') === undefined ? '' : sessionStorage.getItem('menu'),
  button: sessionStorage.getItem('button') === undefined ? '' : sessionStorage.getItem('button'),
  site: sessionStorage.getItem('site') === undefined ? {
    "id": 5,
    "name": "总站",
    "dcity_id": null,
    "city_plate_id": 0,
    "path": "cn",
    "company_name": "江苏土拍信息科技有限公司",
    "address": "总站",
    "phone": "15206213421",
    "adviser": "总站",
    "copyright": "总站",
    "status": 1,
    "created_at": "2022-10-26 09:57:52",
    "updated_at": "2022-10-27 14:37:49",
    "sort": 1,
    "current": false
  } : sessionStorage.getItem('site'),
}

const mutations = {
  userinfo(state, v) {
    // console.log('cunchu', JSON.stringify(v))
    state.userinfo = JSON.stringify(v)
    Cookies.set('userinfo', JSON.stringify(v), 1, '.chinatupai.com')
  },

  SET_TOKEN: (state, token) => {
    state.token = token
  },

  SET_NAME: (state, name) => {
    state.name = name
    // alert('SET_NAME')
  },
  SET_MENU: (state, menu) => {
    // alert('menu')
    state.menu = JSON.stringify(menu)
    sessionStorage.setItem('menu', JSON.stringify(menu));

  },


  SET_BUTTON: (state, button) => {
    // alert('button')
    state.button = JSON.stringify(button)
    sessionStorage.setItem('button', JSON.stringify(button));
  },


  SET_SITE: (state, site) => {
    state.site = JSON.stringify(site)
    sessionStorage.setItem('site', JSON.stringify(site));
  },





}

const actions = {




  login({
    commit
  }, userInfo) {
    const {
      account,
      password
    } = userInfo
    return new Promise((resolve, reject) => {
      login({
        account: account.trim(),
        password: password
      }).then(response => {
        const {
          data
        } = response.data
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        setSuper(data.is_super)

        console.log(getToken(), getSuper())
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user login

   //获取用户信息
  //  userinfo({
  //   commit
  // }) {
  //   info().then(res => {

  //     console.log(res)

  //     const userinfo = res.data
  //     commit('userinfo', userinfo)
  //   })
  // },



  // get user info
  getInfo({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const {
          data
        } = response

        console.log('store 中的getInfo方法')
        if (!data) {
          reject('Verification failed, please Login again.')
        }

        // const { id, account, roles } = data
        const {
          truename,
          menu,
          button
        } = data
        console.log('store 中的getInfo方法2')



        commit('SET_NAME', truename)
        commit('SET_MENU', menu)
        commit('SET_BUTTON', button)
        commit('userinfo', data)
        console.log('store 中的getInfo方法3')

        resolve(data)
      }).catch(error => {
        console.log('store 中的getInfo方法error')
        reject(error)
      })
    })
  },




  // user logout
  logout({
    commit,
    state,
    dispatch
  }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_', '')
        commit('SET_ROLES', '')
        removeToken()
        resetRouter()

        // this.$store.commit('setToken', '')
        // localStorage.setItem('token', '')
        // Cookies.set('token', '')
        // this.$store.commit('userinfo', '')
        // Cookies.set('userinfo', '')
        // Cookies.set('name', '')


        this.$store.commit('setToken', '')
        localStorage.setItem('token', '')
        Cookies.set('token', '', {
          path: '/',
          domain: process.env.VUE_APP_DOMAIN
        })

        Cookies.remove('token')
        this.$store.commit('userinfo', '')
        Cookies.set('userinfo', '', {
          path: '/',
          domain: process.env.VUE_APP_DOMAIN
        })
        Cookies.remove('userinfo')





        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, {
          root: true
        })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({
    commit,
    dispatch
  }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const {
      roles
    } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {
      root: true
    })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, {
      root: true
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}