import memberRequest from '@/utils/memberRequest'


export function memberInfo(params) {
  return memberRequest({
    url: '/members/info',
    method: 'post',
    params
  })


}

//获取用户信息
export function info(params) {
  return memberRequest({
    url: '/members/info',
    method: 'post',
    params
  })

}



//会员退出
export function logout(params) {
  return memberRequest({
    url: '/members/logout',
    method: 'post',
    params
  })
}