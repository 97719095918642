import request from '@/utils/request'
import requestMock from '@/utils/requestMock' //mock数据使用  requestMock需要换成request 删除此行





//获取城市有板块的组合
export function hasBlock(params){
  return request({
      url: '/frontend/bigdata/city-plate/plate',
      method: 'post',
      params
    })
}




//获取地图图片
export function mapPic(params){
  return request({
      url: '/frontend/bigdata/research/map-pic',
      method: 'post',
      params
    })
}



//获取多变形
export function lineData(params){
  return request({
      url: '/frontend/bigdata/research/traffic',
      method: 'post',
      params
    })
}


//获取产业点数据 type 1商业 2产业  3航空  4港口
export function pointData(params){
  return request({
      url: '/frontend/bigdata/research/map-spot',
      method: 'post',
      params
    })
}


//获取当前城市的经纬度
export function blockDetail(params){
  return request({
      url: '/frontend/bigdata/city-plate/lng-lat',
      method: 'post',
      params
    })
}







//获取区位多变形数据
export function polygonData(params){
  return request({
      url: '/frontend/bigdata/research/traffic',
      method: 'post',
      params
    })
}



export function citylist(params){
    return request({
        url: '/frontend/bigdata/city-plate/city',
        method: 'post',
        params
      })
}

//下载zip组建
export function downloadZip(params){
  return request({
      url: '/frontend/bigdata/file-download/batch',
      method: 'post',
      params
    })
}

//城市区位画廊
export function cityLocationImg(params){
  return request({
      url: '/frontend/bigdata/city-plate/location',
      method: 'post',
      params
    })
}

export function intro(params){
    return request({
        url: '/frontend/bigdata/city-plate/intro',
        method: 'post',
        params
      })
}

//行政区划
// export function arealayout(params){
//   return requestMock({
//       url: '/mock/arealayout.json',
//       method: 'get',
//       params
//     })
// }

//行政区划
export function arealayout(params){
  return request({
      url: '/frontend/bigdata/city-plate/division',
      method: 'post',
      params
    })
}


//行政区划下载
// export function areadown(params){
//   return requestMock({
//       url: '/mock/areadown.json',
//       method: 'get',
//       params
//     })
// }

export function areadown(params){
  return request({
    url: '/frontend/bigdata/city-plate/division-excel',
    method: 'post',
    params
  })
}

//城市人口年份
// export function citypopulationYear(params){
//   return requestMock({
//     url: '/mock/citypopulation_year.json',
//     method: 'get',
//     params
//   })
// }

//城市人口年份
export function citypopulationYear(params){
  return request({
    url: '/frontend/bigdata/city-plate/population-year',
    method: 'post',
    params
  })
}




//城市人口列表
// export function citypopulation(params){
//   return requestMock({
//     url: '/mock/citypopulation.json',
//     method: 'get',
//     params
//   })
// }

//城市人口列表
export function citypopulation(params){
  return request({
    url: '/frontend/bigdata/city-plate/population',
    method: 'post',
    params
  })
}





