import router from './router'
import store from './store'
import {
  siteMapDomain
} from './settings'
import {
  Message
} from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
  getToken
} from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'


NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

// const whiteList = ['/login','/index'] // no redirect whitelist
const blockList = [] // 需要验证token的路由  



function getRequestDomain() {
  const hostHeader = window.location.host
  if (hostHeader) {
    // 获取 Host 头部的值，该值包含了当前请求的域名和端口号
    const host = hostHeader.split(':')[0]
    return host
  }
  return ''
}



router.beforeEach(async (to, from, next) => {


  const domain = getRequestDomain() //获取当前域名
  const domainList = siteMapDomain //获取配置中的配置站点和路径映射

    domainList.map(item => {
      if (domain === item.domain) {

        if (to.path === '/cn/index') {
          next({
            path: item.sitePath
          })

          NProgress.done()
        }
      }
    })
  


  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)


  store.commit('setRoute', to)

  // determine whether the user has logged in
  const hasToken = getToken()
  console.log('hasToken', hasToken)

  if (hasToken) {

    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({
        path: '/'
      })
      NProgress.done()
    } else {
      // alert(3333)
      const hasGetUserInfo = store.getters.name
      // alert(hasGetUserInfo)
      if (hasGetUserInfo) {
        // const hasRoles = store.getters.roles && store.getters.roles.length > 0

        // if (hasRoles) {
        next()
      } else {
        try {

          console.log('menustart')
          // // get user info
          // await store.dispatch('user/getInfo')

          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const {
            menu
          } = await store.dispatch('user/getInfo')

          console.log('menu', menu)

          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', menu)
          // const accessRoutes = []


          console.log('accessRoutes', accessRoutes)
          // // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          console.log('accessRoutes')

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({
            ...to,
            replace: true
          })

          // next()


        } catch (error) {

          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          // alert('555')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (blockList.indexOf(to.path) === -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // alert('permission')
      // other pages that do not have permission to access are redirected to the login page.
      // next(`/login?redirect=${to.path}`)
      window.location.href = process.env.VUE_APP_LOGIN_URL + '?url=' + window.btoa(unescape(encodeURIComponent(window.location)))

      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})